import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import Image from 'next/legacy/image';

import { addBookmark, removeBookmark } from 'ducks/client/bookmarks';
import { getThumbnailUrl } from 'lib/util/imageUrl';
import { getShortWeekdayText } from 'lib/util/getShortWeekdayText';
import { useCurrencyFormatter } from 'hooks/useCurrencyFormatter';
import { PMPProductCardViewProps } from 'lib/themes/themes';
import { formattedTotalBookedText } from 'lib/util/formattedTotalBookedText';
import { Weekday } from 'models/product';
import { useCustomerLedgerEnabled } from 'hooks/useCustomerLedgerEnabled';

import styles from './PMPProductCardView.module.css';

const PMPProductCardView = ({
  product,
  isBookmarked,
  ranking,
  displayLabelTypes,
  isRecommended,
  discountText,
  shouldShowTotalBooked,
  tags,
}: PMPProductCardViewProps) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const currencyFormatter = useCurrencyFormatter();
  const customerLedgerEnabled = useCustomerLedgerEnabled();

  const allDaysOfWeek: Weekday[] = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];

  return (
    <div className={styles['c-card']}>
      <div className={styles['c-card__pic']}>
        {product.imageURL && (
          <Image
            alt="product-thumbnail"
            layout="fill"
            src={product.imageURL}
            loader={({ src }) => getThumbnailUrl(src)}
          />
        )}
        <label className={styles['c-card__pic__favo']}>
          <input
            type="checkbox"
            onClick={(e) => e.stopPropagation()}
            onChange={() => {
              isBookmarked
                ? dispatch(removeBookmark(product.id))
                : dispatch(addBookmark(product.id));
            }}
            checked={isBookmarked}
          />
          <img className={styles['on']} src="/static/images/ic_favo_on.svg" alt="favorite on" />
          <img className={styles['off']} src="/static/images/ic_favo_off.svg" alt="favorite off" />
        </label>
        <div className={styles['c-card__pic__labelframe']}>
          {!!ranking && displayLabelTypes.includes('RANKING_GENERAL') && (
            <div className={styles['c-card__pic__labelframe__ranking']}>
              <div>
                <img src="/static/images/ic_ranking_white.svg" alt="ranking" />
              </div>
              <p>{t('High Ranking')}</p>
            </div>
          )}
          {isRecommended && displayLabelTypes.includes('RECOMMENDED') && (
            <div className={styles['c-card__pic__labelframe__recommend']}>
              <div>
                <img src="/static/images/ic_recommend_white.svg" alt="recommend" />
              </div>
              <p>{t('Recommended')}</p>
            </div>
          )}
          {customerLedgerEnabled && product.shouldShowOnlyForLoggedInCustomer && (
            <div className={styles['c-card__pic__labelframe__recommend']}>
              <div>
                <img src="/static/images/ic_recommend_white.svg" alt="recommend" />
              </div>
              <p>{t('Members Only')}</p>
            </div>
          )}

          {!!discountText && displayLabelTypes.includes('PROMOTION') && (
            <div className={styles['c-card__pic__labelframe__promotion']}>{discountText}</div>
          )}
          {!!ranking && displayLabelTypes.includes('RANKING_SPECIFIC') && (
            <div className={styles['c-card__pic__labelframe__rankingnumber']}>
              <div>
                <img src="/static/images/ic_ranking_white.svg" alt="ranking" />
              </div>
              <p>{ranking}</p>
            </div>
          )}
        </div>
      </div>
      <div className={styles['c-card__info']}>
        <h3 className={clsx(styles['c-card__info__ttl'], 'maincolor-txt')}>{product.name}</h3>
        <div className={styles['c-card__header']}>
          {product.reviewCount != null && product.reviewCount != 0 && (
            <div className={styles['c-card__header__star']}>
              <img src="/static/images/ic_star_on.svg" alt="star" />
              <span>{(product.reviewRating ?? 0).toFixed(1)}</span>
              <span>({product.reviewCount})</span>
            </div>
          )}
          {shouldShowTotalBooked && Boolean(product.totalBooked) && (
            <p className={styles['c-card__header__number']}>
              {formattedTotalBookedText(product.totalBooked, t)}
            </p>
          )}
          <div
            className={styles['c-card__header__tags']}
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            {tags.length > 0 && (
              <>
                <div className={clsx(styles['c-card__header__tags__txt'], 'subcolor-bg')}>
                  <div className="maincolor-ic">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                    >
                      <rect width="32" height="32" fill="none" />
                      <g>
                        <path
                          d="M14.17,2.27H4.27a2,2,0,0,0-2,2v9.9a2,2,0,0,0,.59,1.42L16.41,29.14a2,2,0,0,0,2.83,0l9.9-9.9a2,2,0,0,0,0-2.83L15.59,2.86A2,2,0,0,0,14.17,2.27Zm-3.41,8.49a3,3,0,1,1,0-4.25A3,3,0,0,1,10.76,10.76Z"
                          fill="#0094cc"
                        />
                      </g>
                    </svg>
                  </div>
                  {t('View')}
                </div>
                <ul className={clsx(styles['c-card__header__tags__list'], 'maincolor-border')}>
                  {tags.map((tag) => (
                    <li key={tag}>
                      <p>{tag}</p>
                    </li>
                  ))}
                </ul>
              </>
            )}
          </div>
        </div>
        <p className={styles['c-card__info__desc']}>{product.description}</p>
        <p className={styles['c-card__info__week']}>
          {allDaysOfWeek.map((weekday) => (
            <>
              <span
                key={weekday}
                className={clsx(!product.operatingWeekdays.includes(weekday) && styles['close'])}
                style={
                  i18n.language === 'en-US' ? { width: '24px', textAlign: 'center' } : undefined
                }
              >
                {getShortWeekdayText(weekday, t)}
              </span>{' '}
            </>
          ))}
        </p>
        <div className={styles['c-card__info__bottom']}>
          <p className={styles['c-card__info__bottom__place']}>{product.location}</p>
          <p className={clsx(styles['c-card__info__bottom__plice'], 'maincolor-txt')}>
            {currencyFormatter.format(product.lowPrice)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PMPProductCardView;
